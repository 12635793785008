import './App.css';
import Slider,{ SliderTooltip }  from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useState } from 'react';
const { Handle } = Slider;

function App() {
  const [hotness, setHotness] = useState(6);
  const [crazy, setCrazy] = useState(4);

  const hotnessHandle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`Hotness ${value}`}
        visible={dragging}
        
        placement="top"
        key={index}
      >
        <Handle  value={value} placement={""} {...restProps}>🔥</Handle>
      </SliderTooltip>
    );
  };

  const crazyHandle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`Crazy ${value}`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps}>🤪</Handle>
      </SliderTooltip>
    );
  };

  // const rangeHandle = props => {
  //   const { value, dragging, index, ...restProps } = props;
  //   //console.log(props);
  //   return (
  //     <SliderTooltip
  //       prefixCls="rc-slider-tooltip"
  //       overlay={`${value}`}
  //       visible={dragging}
  //       placement="top"
  //       key={index}
  //     >
  //       <Handle value={value} {...restProps} />
  //     </SliderTooltip>
  //   );
  // };
  
  // const setRange = (p) => {
  //   setCrazy(p[0]);
  //   setHotness(p[1]);
  // }


  return (
    <div className="App">
      <header className="App-header">
        <div>Crazy: {crazy}</div><div>Hot: {hotness}</div><div> {hotness<5 ? 'No Go' : crazy > hotness ?  'Danger Zone' : hotness >= 5 && hotness <8 ? 'Fun Zone' : hotness >= 8 && crazy > 7 ? 'Date Zone' : crazy >4? 'Wife Zone' :'Unicorns' }</div>
        <Slider min={1} max={10} defaultValue={6} style={{maxWidth:'80%',margin:16}} handle={hotnessHandle} onChange={setHotness}/>
        <Slider min={1} max={10} defaultValue={4} style={{maxWidth:'80%',margin:16}} handle={crazyHandle} onChange={setCrazy}/>
        {/* <Range min={1} max={10} defaultValue={[4,6]} style={{maxWidth:'80%',margin:16}} allowCrossz handle={rangeHandle} onChange={setRange}/> */}
        <a target="_blank" rel="noreferrer" style={{margin:16,color:'#cccccc'}} href="https://vm.tiktok.com/TTPdhdpVAq/">https://vm.tiktok.com/TTPdhdpVAq/</a>
      </header>
    </div>
  );
}

export default App;
